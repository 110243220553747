$black: #000;
$darkGray: #333;
$gray: #444;
$halfGray: #575757;
$lightGray: #dcdcdc;
$mediumGray: #8b8b8b;
$white: #fff;
$softGreen: #84bd00;

@mixin theme-header {
    width: 100%;
    order: 1;
}

@mixin theme-articles {
    flex: 3;
    box-sizing: border-box;
    margin: 0 1.25rem 1.25rem;
    order: 3;
}

@mixin theme-primary-content {
    font-size: 1.5rem;
    line-height: 1.875rem;
    margin: 0 0 1.5rem;
    text-align: center;
    text-transform: capitalize;
}

@mixin theme-pt-blogcontent {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    flex: none;
    margin: 0 0 1.25rem;
    padding: 0 1.25rem;
}

@mixin theme-article-tile-2-media {
    margin: 0;
    width: auto;
    height: auto;
    flex: 1;
    margin-bottom: 1.25rem;
}

@mixin theme-pdt-container {
    all: unset;
    position: relative;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}
