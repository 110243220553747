/**
 *  Property of OSF GLOBAL SERVICES INC. , an OSF Digital company. OSF remains the sole owner of all right, title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */
 @import "baseTheme/settings";
@import './blogvariables';

$media900: 74.5rem;
$media800: 48rem;
$media480: 30rem;
$media375: 23.4375rem;

/* GENERAL STYLES */
#blogwrapper {
    background-color: white;

    .breadcrumb {
        padding: 1.0rem 0;
    }
}
.article-links {
    line-height: 2.25rem;
    margin: 1.5rem 0;
    text-align: center;

    ul {
        list-style: none;
        display: inline-flex;
    }

    li {
        all: unset;
        margin: 0.313em;
        list-style-type: none;

        i {
            color: $gray;
            padding-right: 0.2em;
            font-size: 1.875em;
        }
    }
}

.dropdown-menu {
    margin-top: -0.1rem;
    margin-left: -0.1rem;
}

.dropdown-submenu {
    position: relative;

    a::after {
        transform: rotate(-90deg);
        position: absolute;
        right: 0.375rem;
        top: 0.8em;
    }

    .dropdown-menu {
        top: 0;
        left: 100%;
        margin-left: 0.1rem;
        margin-right: 0.1rem;
    }
}

/* BLOG FEATURED ARTICLE START */

img {
    display: inline-block;
    line-height: 5;
    position: relative;

    &::before {
        content: '';
        width: 100%;
        height: calc(100% + 0.625rem);
        background: no-repeat center/contain var(--broken-img-url) white;
        position: absolute;
        top: 50%;
        left: -0.0625rem;
        transform: translateY(-50%);
    }
}

.renderContent img,
.renderContent video {
    max-width: 100%;
}

.top-blog-wrapper {
    border-bottom: 0.0625rem solid $lightGray;
    margin: 0 0 1.25rem 0;
    padding: 1.25rem 0;
}
.post-wrapper {
    margin: 1.25rem 0;
    padding: 1.25rem 0;
}

.blog-featured-article {
    position: relative;

    header {
        overflow: hidden;
        text-align: center;

        iframe {
            height: 18.75rem;
            width: 30rem;
        }

        img{
            width: 100%;
        }
    }

    .featured-description {
        position:absolute;
        bottom: 1.25rem;
        padding: 1.25rem;
        width: 100%;
        color: #fff;
        text-align: center;

        a, .text-primary {
            color: #fff !important;
        }

        .badge-primary {
            background-color: transparent;
            color: #fff;
        }
    }
}

.badge-primary{
    font-size: 12px;
    text-transform: uppercase;
    line-height: 16px;
    font-family:"Mallory Light";
    background-color: transparent;
    color: #1E1E1E
}

.blog-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    .card {
        border: 0;
        padding: 0;
        margin: 0;
    }

    .card-body {
        padding: 0;
        margin: 0;
    }
}


.blog-archive-menu {
    .clear-date-filter {
        border-radius: 0;
        margin: 0 auto 0.9375rem;
    }

    .date-cheked {
        a {
            text-decoration: underline;
            font-weight: 800;
            background: $lightGray;
        }
    }

    .blog-year {
        display: block;
        border-bottom: 0.0625rem solid $lightGray;

        a {
            display: block;
            line-height: 1.125rem;
            padding: 0.625rem 0;
            text-transform: uppercase;
            letter-spacing: 0.125rem;
            color: $darkGray !important;
            font-weight: bold;
            text-align: center;
            font-size: 0.875rem;
        }
    }

    .blog-month {
        display: block;

        a {
            display: block;
            line-height: 1.125rem;
            text-align: left;
            text-transform: uppercase;
            letter-spacing: 0.125rem;
            color: $darkGray !important;
            padding: 0.625rem 1.25rem;
            font-size: 0.75rem;
        }
    }

    .blog-year:hover,
    .blog-month:hover {
        a {
            text-decoration: underline;
            background: $lightGray;
        }
    }

    .blog-archive-menu__expand {
        display: none;
    }

    .blog-year-wrapper {
        border-bottom: 0.0625rem solid $lightGray;

        &:first-child {
            border-top: 0.0625rem solid $lightGray;
        }
    }
}

.pdp-link .link {
    color: var(--skin-primary-color-1);
}

.card-img-top {
    aspect-ratio: var(--image-aspect-ratio);
    object-fit: var(--image-object-fit);
    border-radius: 12px;
}

.categories-list{
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0 auto;
    scrollbar-width: none;
    padding: 0;
    scroll-behavior: smooth;
    display: flex;
    width: 100%;

        .categories-nav {
            display: flex;
            padding: 0;
            margin: 0 auto;
            flex-flow: unset;
            align-content: unset;
            max-height: 2.375rem;
            text-align: center;
            align-items: flex-start;
            gap: 12px;
            list-style: none;

            li a{
                white-space: nowrap;
                font-size: 14px;
                font-family:"Mallory Light";
                border: 1px solid #DDDDDD;
                border-radius: 16px;
                padding: 6px 12px;
            }
        }
}

.blog-post-wrapper {
    display: flex;
    flex-flow: column;
    gap: 24px;
}
.top-articles {
    overflow-x: auto;

    .blog-popular-posts {
        padding: 32px;
        background-color: #FAF8F5;
        border-radius: 12px;
    }

    .popular-post {
        display: flex;
        gap: 24px;
        justify-content: space-between;
        flex-wrap: wrap;

        .post-image {
            width: 146px;
            border-radius: 16px;
        }
        .post-body {
            flex: 1;
        }
    }
}


.blog-related-posts {
    .card {
        border: 0;
    }
}

.blog-content-asset{
    header {
        padding: 1.125rem 0;
    }
}

.creative-wrapper {
    display: flex;
    background-color: #DFE5DF;
    border-radius: 12px;
    overflow: hidden;
    align-items: center;

    .creative-content {
        padding: 2.0rem 3.125rem;
        text-align: center;

        .content-sub-title {
            font-family: Mallory;
            font-size: 16px;
            color: #1E1E1E;
        }

        .content-title {
            font-family: Gotu;
            font-size: 24px;
            font-style: normal;
            color: #1E1E1E;
        }
    }
}


@media only screen and (max-width: $media900) {
    .top-articles {
        .blog-popular-posts {
            padding: 16px 0;
        }
    
        .popular-post {
            gap: 16px;
    
            .post-image {
                width: 65px;
                border-radius: 16px;
            }
            
        }
    }
}

@media only screen and (max-width: $media800) {
    .blog-archive-menu {
        .blog-archive-menu__expand {
            display: block;
            cursor: pointer;
        }

        .blog-year-wrapper,
        .blog-archive-menu__expand,
        .blog-month a {
            text-align: center;
        }

        .blog-archive-menu__expand:hover {
            background: $lightGray;
        }

        .blog-year {
            border-bottom: none;
        }

        .blog-month {
            display: none;
        }
    }

    .blog-grid{
        display: flex;
        flex-flow: row wrap;
        .card {
            width: 47.4%;

            h5 {
                font-size: 16px;
            }

            .badge-primary {
                font-size: 10px;
            }
        }

        #article-count,
        .article-pagination-wrapper {
            width: 100%;
        }
    }

    .blog-related-posts {
        h5 {
            font-size: 16px;
        }

        .badge-primary {
            font-size: 10px;
        }
    }
    
    .top-articles {
        margin-top: 24px;
    
        .blog-popular-posts {
            padding: 16px 0;
        }
    
        .popular-post {
            gap: 16px;
    
            .post-image {
                width: 65px;
                border-radius: 16px;
            }
            
        }
    }
}

@media only screen and (max-width: $media480) {
    .blog-grid{
        display: flex;
        flex-flow: row wrap;
        .card {
            width: 47.4%;

            h5 {
                font-size: 16px;
            }

            .badge-primary {
                font-size: 10px;
            }
        }

        #article-count,
        .article-pagination-wrapper {
            width: 100%;
        }
    }

    .blog-related-posts {
        h5 {
            font-size: 16px;
        }

        .badge-primary {
            font-size: 10px;
        }
    }

    .blog-section {
        padding: 0;
    }

    .top-articles {
        margin-top: 24px;
    
        .blog-popular-posts {
            padding: 16px 0;
        }
    
        .popular-post {
            gap: 16px;
    
            .post-image {
                width: 65px;
                border-radius: 16px;
            }
            
        }
    }

    .creative-wrapper {
        flex-flow: column;
    }
}



.post-section {
    h1 {
        font-size: 36px;
        letter-spacing: -0.04em;
    }

    h2 {
        font-size: 30px;
        letter-spacing: -0.04em;
    }

    h3 {
        font-size: 24px;
        letter-spacing: -0.04em;
    }

    h4 {
        font-size: 20px;
        letter-spacing: -0.04em;
    }

    h5 {
        font-size: 18px;
        letter-spacing: -0.04em;
    }

    img {
        border-radius: 12px;
    }
}

.content-asset {
    .blog-content-asset {
        .post-section{
            a {
                color: #6E224E !important;
                // font-weight: bold;
                text-decoration: underline;
            }
        }
    }
}


.content-asset {
    .blog-content-asset {
        .product-tile {
            .pdp-link a {
                color: var(--skin-primary-color-1) !important;
                text-decoration: none;
            }

            .btn.btn-primary.quickview {
                text-decoration: none;
                @include media-breakpoint-up(sm) {
                    .icon-cart-quickview {
                        width: 30px !important;
                    }
                }
                .text-uppercase.button-label {
                    color: #fff;
                }
            }
        }
    }
}